import React, { useEffect, useRef, useState } from "react";
import classnames from "classnames";

import { MainNav, MobileMenu, SecondaryNav, TrackedLink } from "../index";
import { Logo } from "../../svgs";

import "./page-header.scss";

const PageHeader = ({ miniNav, setNoScroll }) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [atTop, setAtTop] = useState(true);
  const [scrollDirUp, setScrollDirUp] = useState(true);
  const ref = useRef();
  const prevScrollY = useRef();

  const handleToggleClick = () => {
    setNoScroll(!mobileMenuOpen);
    setMobileMenuOpen(!mobileMenuOpen);
  };

  /**
   * Updates scroll direction.
   * @returns void
   */
  const handleScroll = () => {
    if (!window) return;
    const { innerHeight, scrollY } = window;

    if (scrollY < 100) {
      setAtTop(true);
      setScrollDirUp(true);
    } else {
      setAtTop(false);

      if (prevScrollY.current < scrollY) {
        setScrollDirUp(false);
      }

      if (prevScrollY.current > scrollY) {
        setScrollDirUp(true);
      }
    }

    prevScrollY.current = scrollY;
  };

  /**
   * Add scroll listener to hide/show nav.
   */
  useEffect(() => {
    if (window) {
      window.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (window) {
        window.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  return (
    <header
      className={classnames("page-header", {
        "page-header--transparent-bg": miniNav,
        "page-header--hidden": !scrollDirUp,
        "page-header--collapsed": !atTop,
      })}
      ref={ref}
      style={
        atTop
          ? {
              backgroundColor: "transparent",
            }
          : {}
      }
    >
      <div className="page-header__container">
        <h1 className="page-header__logo">
          <span className="screen-hidden">Partie</span>
          <TrackedLink type="page-header__home-link" to="/" label="Partie Matchmaking Home" children={<Logo />} />
        </h1>

        {!miniNav && (
          <>
            <MainNav />
            {mobileMenuOpen && <MobileMenu setNoScroll={setNoScroll} />}
            <SecondaryNav />
            <button
              className={classnames("page-header__mobile-menu-toggle", {
                "page-header__mobile-menu-toggle--open": mobileMenuOpen,
              })}
              aria-label="mobile menu button"
              onClick={handleToggleClick}
            >
              <span />
              <span />
              <span />
            </button>
          </>
        )}
      </div>
    </header>
  );
};

export default PageHeader;
